export interface Recipient {
  userId?: string
  firstName?: string
  lastName?: string
  email?: string
  orgName?: string
  role?: string
  oorTitle?: string
  shortTitle?: string
  elfCode?: string
  status?: string
  error?: RecipientInputError | null
}

interface RecipientInputError {
  firstName?: string
  lastName?: string
  middleName?: string
  email?: string
  orgName?: string
  oorTitle?: string
}

export interface WorkflowArgs {
  workflowInstanceId?: string
  requesterId?: string
  requesterFirstName?: string
  requesterLastName?: string
  orgId?: string
  orgName?: string
}

export enum WorkflowDisplayMode {
  EDITABLE = 'editable',
  NON_EDITABLE = 'non_editable'
}
