import { Button } from '@/components/base/Button'
import useStore from '@/state/store'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEcrWorkflowStore } from '../store'
import { Recipient } from '../types'
import RecipientTextInput from './recipient-text-input'

interface IProps {
  recipient: Recipient
  index: number
  isReadOnlyMode: boolean
}

const RecipientCard = ({
  recipient,
  index,
  isReadOnlyMode,
  ...props
}: IProps) => {
  const { formatMessage } = useIntl()
  const { deleteRecipient, updateRecipient } = useEcrWorkflowStore()
  const {
    authentication: { userInfo }
  } = useStore()

  const handleInputChange =
    (field: keyof Recipient) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateRecipient(index, { ...recipient, [field]: event.target.value })
    }

  const handleRemoveRecipient = () => {
    deleteRecipient(index)
  }

  const isCurrentUser = () => {
    return userInfo?.emails?.some((email) => email.value === recipient?.email)
  }

  return (
    <>
      <div className="representative-box" {...props}>
        {/* Input fields ... */}
        <div className="p-4 mt-6 border rounded-lg shadow-md bg-grey125">
          <div className="flex justify-between  border-b border-[#E8E8E8]">
            <h3 className="text-lg font-medium">
              {index + 1}
              {'. '}
              {formatMessage({
                id: 'ecr.task.recipient.proposedOOR.title'
              })}
            </h3>
            <div className="mb-1 flex1">
              <Button
                mode="danger"
                size="small"
                disabled={isReadOnlyMode}
                label={<FormattedMessage id="cta.delete" />}
                onClick={handleRemoveRecipient}
              />
            </div>
          </div>
          <form className="grid gap-4 mt-2 md:grid-cols-2">
            {/* Row 1 */}
            <RecipientTextInput
              id="input.defineRepresentatives.firstName"
              htmlFor="firstName"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.firstName.placeholder'
              })}
              value={recipient.firstName}
              disabled={isReadOnlyMode || isCurrentUser()}
              onChange={handleInputChange('firstName')}
              error={
                recipient.error?.firstName ? recipient.error?.firstName : ''
              }
            />
            <RecipientTextInput
              id="input.defineRepresentatives.lastName"
              htmlFor="lastName"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.lastName.placeholder'
              })}
              value={recipient.lastName}
              disabled={isReadOnlyMode || isCurrentUser()}
              onChange={handleInputChange('lastName')}
              error={recipient.error?.lastName ? recipient.error?.lastName : ''}
            />

            {/* Row 2 */}
            <RecipientTextInput
              id="input.defineRepresentatives.email"
              htmlFor="email"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.email.placeholder'
              })}
              value={recipient.email}
              disabled={isReadOnlyMode || isCurrentUser()}
              onChange={handleInputChange('email')}
              error={recipient.error?.email ? recipient.error?.email : ''}
            />

            <RecipientTextInput
              id="input.defineRepresentatives.companyName"
              htmlFor="orgName"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.orgName.placeholder'
              })}
              value={recipient.orgName}
              disabled={isReadOnlyMode || isCurrentUser()}
              onChange={handleInputChange('orgName')}
              error={recipient.error?.orgName ? recipient.error?.orgName : ''}
            />
            <div className="flex flex-col mb-2 ">
              <RecipientTextInput
                id="ecr.task.recipient.addRecipient.label.ecrTitle"
                htmlFor="ecrTitle"
                placeholder={formatMessage({
                  id: 'oor.task.recipient.input.oorTitle.placeholder'
                })}
                value={recipient.oorTitle}
                disabled={isReadOnlyMode}
                onChange={handleInputChange('oorTitle')}
                error={
                  recipient.error?.oorTitle ? recipient.error?.oorTitle : ''
                }
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default RecipientCard
