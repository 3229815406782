import constants from '@/config/constants'
import EcrWorkflowTask from '@/types/ecr-workflow-task'

const { ROLES } = constants

export const EcrTaskPages: Record<
  string,
  {
    taskType: string
    label: string
    href: string
    grants?: string[]
    excludes?: string[]
    isPublic: boolean
  }
> = {
  [EcrWorkflowTask.INTRODUCTION]: {
    taskType: EcrWorkflowTask.INTRODUCTION,
    label: 'ecr.menu.task.introduction',
    href: '/ecr/introduction',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [EcrWorkflowTask.PROPOSE_RECIPIENTS]: {
    taskType: EcrWorkflowTask.PROPOSE_RECIPIENTS,
    label: 'ecr.menu.task.proposeRecipient',
    href: '/ecr/recipient',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [EcrWorkflowTask.IDENTITY_VERIFICATION]: {
    taskType: EcrWorkflowTask.IDENTITY_VERIFICATION,
    label: 'ecr.menu.task.idVerification',
    href: '/ecr/id-verification',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [EcrWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY]: {
    taskType: EcrWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY,
    label: 'ecr.menu.task.scheduleCeremony',
    href: '/ecr/schedule',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [EcrWorkflowTask.ISSUANCE_CEREMONY]: {
    taskType: EcrWorkflowTask.ISSUANCE_CEREMONY,
    label: 'ecr.menu.task.issuanceCeremony',
    href: '/ecr/issuance-ceremony',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  }
}

export const EcrTaskPageMapping: {
  taskType: string
  label: string
  href: string
  grants?: string[]
  excludes?: string[]
  isPublic: boolean
}[] = Object.values(EcrTaskPages)
