import { Button } from '@/components/base/Button'
import Spinner from '@/components/base/Spinner'
import Arrow from '@/components/icons/Arrow'
import TaskStatus from '@/enums/taskStatus'
import { TaskActor } from '@/models/workflow/task-actor'
import { FormattedMessage, useIntl } from 'react-intl'
import tailwindConfig from 'tailwind.config'
interface IProps {
  onClick: () => void
  isLoading: boolean
  assignedTask: TaskActor
}

const ProposeRecipientFooter = ({
  isLoading,
  onClick,
  assignedTask
}: IProps) => {
  const { formatMessage } = useIntl()
  return (
    <div className="flex justify-between  ml-[26%]">
      <Button
        mode="secondary"
        label={formatMessage({ id: 'cta.previous' })}
        iconLeft={<Arrow direction={'LEFT'} color="blue" />}
        onClick={() => window.history.back()}
      />
      {assignedTask && (
        <Button
          label={
            assignedTask.status === TaskStatus.COMPLETE ? (
              <FormattedMessage id="cta.next" />
            ) : (
              <FormattedMessage id="oor.task.recipient.cta.submit" />
            )
          }
          onClick={onClick}
          iconRight={
            isLoading ? (
              <Spinner color="info" aria-label="loading..." />
            ) : (
              <Arrow
                direction="RIGHT"
                color={tailwindConfig.theme.extend.colors.white}
              />
            )
          }
          disabled={isLoading}
        />
      )}
    </div>
  )
}

export default ProposeRecipientFooter
