import React from 'react'
import style from '@/styles/table.module.scss'
import { FormattedMessage } from 'react-intl'

export interface IColumn {
  label: React.ReactNode
  key: string
  id?: string
  className?: string
}

export interface ITableData {
  [key: string]: React.ReactNode
}

export interface IProps {
  noDataText?: React.ReactNode
  headerColumns: IColumn[]
  data: ITableData[]
  isLoading?: boolean
  className?: string
  headerClassName?: string
  tdClassName?: string
  dataTestId?: string
}

const Table = ({
  headerColumns,
  data,
  isLoading = false,
  noDataText = <FormattedMessage id="base.table.noData" />,
  className = '',
  headerClassName = '',
  tdClassName = '',
  dataTestId
}: IProps) => {
  return (
    <div className={`${style.table} ${className}`}>
      <table data-testid={dataTestId}>
        <thead
          className={`border-b border-solid border-grey200 text-neutralsAdditional800 ${headerClassName}`}
        >
          <tr className="border-b border-solid border-grey200">
            {headerColumns.map((column, key) => {
              return (
                <th
                  key={key}
                  className={`font-medium text-base ${
                    column.className ? column.className : ''
                  } `}
                >
                  {column.label}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td
                colSpan={headerColumns.length}
                style={{ textAlign: 'center' }}
                className={tdClassName}
              >
                <FormattedMessage id="base.state.loading" />
              </td>
            </tr>
          )}
          {!isLoading && !data?.length && (
            <tr>
              <td
                colSpan={headerColumns.length}
                style={{ textAlign: 'center' }}
                className={tdClassName}
              >
                {noDataText}
              </td>
            </tr>
          )}
          {data?.map((item, key) => {
            const rowClass = item?.rowStyle ? item.rowStyle.toString() : ''
            return (
              <tr key={key} className={`hover:bg-grey100 ${rowClass}`}>
                {headerColumns.map((column, columnKey) => {
                  return (
                    <td
                      data-testid={item[column.key]}
                      key={columnKey}
                      className={`font-normal text-base ${
                        column.className ? column.className : ''
                      } ${tdClassName}`}
                    >
                      {item[column.key]}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
export default Table
