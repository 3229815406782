import Heading from '@/components/typography/heading'
import { FormattedMessage } from 'react-intl'

interface IProps {
  className?: string
}

const ProposeRecipientDescription = ({
  className = 'pt-4 pb-6 pl-8 pr-4 border-b border-b-grey200'
}: IProps) => {
  return (
    <div className={className}>
      <div className="max-w-[650px]">
        <Heading className="mb-2">
          <FormattedMessage id="ecr.task.recipient.heading" />
        </Heading>
        <p className="mb-4">
          <FormattedMessage id="page.generic.ecr.recipient.text1" />
        </p>
        <p>
          <FormattedMessage id="page.generic.ecr.recipient.text2" />
        </p>
      </div>
    </div>
  )
}

export default ProposeRecipientDescription
