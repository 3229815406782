import NextStepButton from '@/components/meet/NextStepButton'

interface IProps {
  onClick: () => void
  isLoading: boolean
}

const IntroductionFooter = ({ onClick, isLoading }: IProps) => {
  return (
    <div className="flex flex-row-reverse">
      <NextStepButton onClick={onClick} loading={isLoading} />
    </div>
  )
}

export default IntroductionFooter
