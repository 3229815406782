import ProposeRecipientFooter from '@/features/ecr/recipient/ProposeRecipientFooter'
import ProposeRecipientDescription from './ProposeRecipientDescritption'
import { ProposeRecipients } from './propose-recipients'
import ProposeRecipientLoader from './ProposeRecipientLoader'
import ProposeRecipientInfoNote from './ProposeRecipientInfoNote'
export {
  ProposeRecipients,
  ProposeRecipientFooter,
  ProposeRecipientDescription,
  ProposeRecipientLoader,
  ProposeRecipientInfoNote
}
