import { create } from 'zustand'
import { produce, enableMapSet } from 'immer'
import { Recipient, WorkflowDisplayMode } from './types'
import TaskStatus from '@/enums/taskStatus'
import SchedulingStatus from '@/enums/schedulingStatus'
import { EcrWorkflow } from '@/models/ecr-vlei-workflow'
import { devtools } from 'zustand/middleware'
import constants from '@/config/constants'

export interface ITimeSlots {
  start: string
  end: string
}

type EcrWorkflowStore = {
  workflow: EcrWorkflow
  recipients: Recipient[]
  mode: WorkflowDisplayMode
  scheduling: {
    status: TaskStatus
    qarTimeSlot: ITimeSlots[]
    ceremonyTime?: string
    inviteeLARs: Set<string>
    moderatorUserId: string
  }
  setMode: (mode: WorkflowDisplayMode) => void
  setWorkflow: (data: EcrWorkflow) => void
  setRecipients: (recipients: Recipient[]) => void
  addRecipient: () => void
  deleteRecipient: (index: number) => void
  updateRecipient: (index: number, data: Recipient) => void
  setQarTimeSlot?: (qarTimeSlot: { start: string; end: string }[]) => void
  setCeremonyTime?: (ceremonyTime: string) => void
  resetRecipients: () => void
  addInviteeLARs: (larId: string) => void
  removeInviteeLARs: (larId: string) => void
  addModerator: (moderatorId: string) => void
}

enableMapSet()

export const useEcrWorkflowStore = create<EcrWorkflowStore>()(
  devtools(
    (set, get) => ({
      workflow: null,
      scheduling: {
        status: TaskStatus.ASSIGNED,
        qarTimeSlot: [],
        inviteeLARs: new Set<string>(),
        moderatorUserId: null
      },
      recipients: [
        {
          userId: '',
          firstName: '',
          lastName: '',
          email: '',
          orgName: '',
          oorTitle: '',
          shortTitle: '',
          elfCode: ''
        }
      ], // Start with one empty recipient
      mode: WorkflowDisplayMode.NON_EDITABLE,
      setWorkflow: (data: EcrWorkflow) =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.workflow = data
          })
        ),
      setRecipients: (recipients: Recipient[]) =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.recipients = recipients
          })
        ),
      setMode: (mode: WorkflowDisplayMode) =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.mode = mode
          })
        ),
      addRecipient: () =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.recipients.push({
              firstName: '',
              lastName: '',
              email: '',
              orgName: '',
              oorTitle: '',
              shortTitle: '',
              elfCode: ''
            })
          })
        ),
      deleteRecipient: (index: number) =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.recipients.splice(index, 1)
          })
        ),
      updateRecipient: (index: number, data: Recipient) =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.recipients[index] = { ...state.recipients[index], ...data }
          })
        ),
      setQarTimeSlot: (qarTimeSlot: ITimeSlots[]) =>
        set((state) => ({
          ...state,
          scheduling: {
            ...state.scheduling,
            qarTimeSlot: qarTimeSlot
          }
        })),
      setCeremonyTime: (ceremonyTime: string) =>
        set((state) => ({
          ...state,
          scheduling: {
            ...state.scheduling,
            ceremonyTime: ceremonyTime
          }
        })),
      resetRecipients: () =>
        set(
          produce((state: EcrWorkflowStore) => {
            state.recipients = [
              {
                userId: '',
                firstName: '',
                lastName: '',
                email: '',
                orgName: '',
                oorTitle: '',
                shortTitle: '',
                elfCode: '',
                error: null
              }
            ]
          })
        ),
      addInviteeLARs: (larId: string) => {
        set(
          produce((state: EcrWorkflowStore) => {
            state.scheduling.inviteeLARs = new Set([
              ...state.scheduling.inviteeLARs,
              larId
            ])
          })
        )
      },
      removeInviteeLARs: (larId: string) => {
        set((state) => ({
          ...state,
          scheduling: {
            ...state.scheduling,
            inviteeLARs: new Set(
              [...state.scheduling.inviteeLARs].filter((id) => id !== larId)
            )
          }
        }))
      },
      addModerator: (moderatorId: string) => {
        set(
          produce((state) => {
            state.scheduling.moderatorUserId = moderatorId
          })
        )
      }
    }),
    { enabled: constants.PROFILE === 'development' }
  )
)
