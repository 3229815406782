import { Button } from '@/components/base/Button'
import Card from '@/components/base/Card'
import Info from '@/components/icons/Info'
import { FormattedMessage } from 'react-intl'
import tailwindConfig from 'tailwind.config'

interface IProps {
  hidden: boolean
  onClick: () => void
}

const ProposeRecipientInfoNote = ({ hidden, onClick }: IProps) => {
  return (
    !hidden && (
      <Card mode="INFO" className="flex items-start gap-5">
        <div className="self-start">
          <Info
            weight="fill"
            fill={tailwindConfig.theme.extend.colors.royalBlue}
          />
        </div>
        <span className="font-normal text-blue-800">
          <p className="mb-4">
            <FormattedMessage id="page.generic.ecr.recipient.infoText1" />
          </p>
          <p className="mb-4">
            <FormattedMessage id="page.generic.ecr.recipient.infoText2" />
          </p>
        </span>

        <Button
          mode="secondary"
          label={<FormattedMessage id="cta.dismiss" />}
          onClick={onClick}
        />
      </Card>
    )
  )
}

export default ProposeRecipientInfoNote
