import { commonWorkflowService, ecrPrerequisitesService } from '@/utils/apis'
import {
  type EcrPrerequisitesRequestDto,
  EcrProposalRequest,
  type EcrProposalRequestDto,
  EcrRemovalRequest,
  type EcrRemovalRequestDto,
  type EcrResentNotificationRequestDto,
  FindCeremonyParticipants1Request,
  FindScheduledMeetingRequest,
  ProposedECRsRequest,
  ResendNotificationToEcrRecipientRequest,
  ScheduleCeremony2Request
} from '@/api/origin-workflow-svc'

export const startWorkflow = async (
  ecrPrerequisitesRequestDto: EcrPrerequisitesRequestDto
) => {
  return await ecrPrerequisitesService.start2({
    ecrPrerequisitesRequestDto
  })
  // return await post('/ecr-prereq/start', ecrPrerequisitesRequestDto, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function proposeRecipients(
  ecrProposalRequestDto: EcrProposalRequestDto
) {
  return await ecrPrerequisitesService.ecrProposal({
    ecrProposalRequestDto
  } as EcrProposalRequest)
  // return await post(`/ecr-prereq/ecr-proposal`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getProposedEcrRecipientsByWorkflowInstanceId(
  workflow: string
) {
  return ecrPrerequisitesService.proposedECRs({
    workflow
  } as ProposedECRsRequest)
  // return await get(`/ecr-prereq/proposed-recipients/${workflowInstanceId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getProposedEcrRecipientsByMeetingId(meeting: string) {
  return await ecrPrerequisitesService.proposedECRs({
    meeting
  } as ProposedECRsRequest)

  // return await get(`/ecr-prereq/proposed-recipients/meeting/${meetingId}`, {
  //   BASE_URL: constants.WORKFLOW_URLF
  // })
}

export async function scheduleCeremony(
  scheduleCeremonyRequestDto: ScheduleCeremony2Request
) {
  return await ecrPrerequisitesService.scheduleCeremony2(
    scheduleCeremonyRequestDto
  )
}

export async function getCeremonyParticipants(workflow: string) {
  return await ecrPrerequisitesService.findCeremonyParticipants1({
    workflow
  } as FindCeremonyParticipants1Request)
  // return await get(`/ecr-prereq/ceremony-participants/${orgId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getCeremonyTime(workflow: string) {
  return await commonWorkflowService.findScheduledMeeting({
    workflow
  } as FindScheduledMeetingRequest)
  // return await get(`/common/scheduled-meeting/${workflowInstanceId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

// export const getEcrTitles = async (country: string): Promise<EcrTitle[]> => {
//   return await get(`/oor-title/titles?country=${country}&elfCode=0000`, {
//     BASE_URL: constants.AUTH_URL
//   })
// }

export async function removeECRRecipient(
  ecrRemovalRequestDto: EcrRemovalRequestDto
) {
  return await ecrPrerequisitesService.ecrRemoval({
    ecrRemovalRequestDto
  } as EcrRemovalRequest)
  // return await post(`/ecr-prereq/ecr-removal`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function resendECRInvitation(
  ecrResentNotificationRequestDto: EcrResentNotificationRequestDto
) {
  return await ecrPrerequisitesService.resendNotificationToEcrRecipient({
    ecrResentNotificationRequestDto
  } as ResendNotificationToEcrRecipientRequest)
  // return await post(`/ecr-prereq/resent-notification`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}
