export interface ProposeRecipientRequest {
  workflowInstanceId: string
  workflowInstanceAssignedTaskId?: string
  orgId: string
  proposedUsers: ProposedUser[]
}

export interface ProposedUser {
  userId?: string
  firstName?: string
  lastName?: string
  orgName?: string
  email?: string
  oorTitle?: string
  shortTitle?: string
  elfCode?: string
}

export interface ProposedRecipientDto {
  recipients: Recipient[]
}

interface Recipient {
  userId: string
  firstName: string
  lastName: string
  emails: string[]
  orgName: string
  titleAndGrantStatusList: TitleAndGrantStatus[]
}

interface TitleAndGrantStatus {
  shortTitle: string
  title: string
  grantStatus: string
}

export interface AddPublicRecordsRequest {
  workflowInstanceId: string
  workflowInstanceAssignedTaskId: string
  orgId: string
  publicRecordLinks?: Array<string>
}

export interface IdVerificationRequest {
  workflowInstanceId: string
  workflowInstanceAssignedTaskId?: string
  orgId: string
  countryCode: string
  phoneNumber: string
}

export interface QarOorVerificationRequest {
  orgId: string
  workflowInstanceId: string
  workflowInstanceAssignedTaskId: string
  approval: boolean
}

export interface ScheduleCeremonyRequest {
  workflowInstanceId: string
  workflowInstanceAssignedTaskId: string
  orgId: string
  timeSlot: TimeSlot
}

export interface TimeSlot {
  start?: string
  end?: string
}

export interface Invitee {
  userId: string
  firstName: string
  lastName: string
  emails: string[]
  orgName: string
  recipient?: boolean
  titleAndGrantStatusList: TitleAndGrantStatus[]
}

export interface InviteesList {
  recipients: Invitee[]
}

interface Attendee {
  userId: string
  name: string
}

export interface CeremonyResponse {
  attendees: Attendee[]
  ceremonyTime: TimeSlot
  title: string
  meetingUrl: string
}

export interface EcrTitle {
  title?: string
  shortTitle?: string
  elfCode?: string
  country?: string
}

export enum RecipientStatus {
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETED',
  REJECTED = 'REJECTED'
}

export interface RemoveECRREcipientRequest {
  oorTitle: string
  orgId: string
  userId: string
  workflowInstanceAssignedTaskId: string
  workflowInstanceId: string
}

export interface ResendECRInvitation {
  userId: string
  workflowInstanceId: string
  email: string
}
