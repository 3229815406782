import Spinner from '@/components/base/Spinner'
import { FormattedMessage } from 'react-intl'

interface IProps {
  className?: string
}

const ProposeRecipientLoader = ({
  className = 'flex items-center justify-center gap-2 py-6'
}: IProps) => {
  return (
    <div className={className}>
      <Spinner />
      <span>
        <FormattedMessage id="loading.task" />
      </span>
    </div>
  )
}

export default ProposeRecipientLoader
