import { IconWeight, Info } from 'phosphor-react'
import React from 'react'

interface IProps {
  size?: number
  color?: string
  weight?: IconWeight
  fill?: string
}

const InfoIcon = ({
  color = '#000',
  size = 25,
  weight = 'regular',
  fill = '#ffffff'
}: IProps) => {
  return <Info size={size} color={color} weight={weight} fill={fill} />
}

export default InfoIcon
