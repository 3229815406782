import { FormattedMessage } from 'react-intl'
import { Button } from '../base/Button'
import Arrow from '../icons/Arrow'
import tailwindConfig from 'tailwind.config'
import Spinner from '../base/Spinner'

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  mode?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link'
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium'
  /**
   * Button contents
   */
  label?: React.ReactNode
  /**
   * Button right icon
   */
  iconRight?: React.ReactNode
  /**
   * Button left icon
   */
  iconLeft?: React.ReactNode
  /**
   * Optional click handler
   */
  onClick?: () => void

  type?: 'button' | 'submit' | 'reset'

  disabled?: boolean

  className?: string
  loading?: boolean
}

const NextStepButton = ({
  mode = 'primary',
  size = 'small',
  backgroundColor,
  type = 'button',
  disabled = false,
  loading = false,
  className = '',
  iconLeft,
  iconRight = loading ? (
    <div className="ml-2">
      <Spinner color="info" aria-label="loading..." />
    </div>
  ) : (
    <Arrow direction="RIGHT" color={tailwindConfig.theme.extend.colors.white} />
  ),
  label = <FormattedMessage id="cta.next" />,
  ...props
}: ButtonProps) => {
  return (
    <Button
      mode={mode}
      className={className}
      size={size}
      backgroundColor={backgroundColor}
      type={type}
      disabled={disabled || loading}
      iconLeft={iconLeft}
      iconRight={iconRight}
      label={label}
      {...props}
    />
  )
}

export default NextStepButton
