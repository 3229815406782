import React from 'react'

interface IProps {
  children: React.ReactNode
  type?: 'plain' | 'dotted' | 'dashed'
  mode?: 'INFO' | 'WARNING' | 'DANGER' | 'DEFAULT' | 'SUCCESS'
  round?: boolean
  className?: string
  style?: React.CSSProperties
}

const Card = ({
  children,
  type = 'plain',
  mode = 'DEFAULT',
  className = '',
  round = true,
  style = {}
}: IProps) => {
  const typeClass =
    type === 'dotted'
      ? 'border-dotted'
      : type === 'dashed'
      ? 'border-dashed'
      : ''

  let modeSpecificClasses = ''
  if (mode === 'INFO')
    modeSpecificClasses = 'bg-SecondaryBlue50 border-primaryAdditional200'
  else if (mode === 'WARNING')
    modeSpecificClasses =
      'bg-SecondaryOrange50 border-SecondaryOrange200 text-SecondaryOrange900'
  else if (mode === 'DANGER')
    modeSpecificClasses =
      'bg-SecondaryRed50 border-SecondaryRed200 text-SecondaryRed900'
  else if (mode === 'SUCCESS')
    modeSpecificClasses =
      'bg-secondaryGreen50 border-SecondaryGree100 text-SecondaryGreen900'

  return (
    <div
      className={`p-3 border ${
        round && 'rounded'
      } ${typeClass} ${className} ${modeSpecificClasses}`}
      style={style}
    >
      {children}
    </div>
  )
}
export default Card
