import { usePathname } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TaskMenuItem } from '@/types/task-menu-item'
import { SideMenuItem } from './side-menu-item'
import { EcrTaskPageMapping } from '../task-page-mapping'
import useStore from '@/state/store'
import { useEcrWorkflowStore } from '../store'
import EcrWorkflowTask from '@/types/ecr-workflow-task'
import TaskStatus from '@/enums/taskStatus'
import { Task } from '@/models/workflow/task'
import TaskType from '@/enums/taskType'

export const SideMenu = () => {
  const pathname = usePathname()
  const { workflow } = useEcrWorkflowStore()
  const { authentication } = useStore()
  const [taskMenuItems, setTaskMenuItems] = useState([])

  const getHref = (menuItem, requesterId) => {
    if (menuItem.taskType === EcrWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY) {
      return requesterId === authentication.userInfo.id
        ? '/ecr/schedule'
        : '/ecr/schedule/status'
    }
    return menuItem.href
  }

  const getDisabledOrNot = (taskType: EcrWorkflowTask) => {
    let isDisabled = false
    if (taskType == EcrWorkflowTask.PROPOSE_RECIPIENTS) {
      const currentTask = workflow?.findTaskByType(
        EcrWorkflowTask.PROPOSE_RECIPIENTS
      )
      const prevTask = workflow?.findTaskByType(EcrWorkflowTask.INTRODUCTION)
      if (!prevTask?.isTaskCompleted() && !currentTask?.isTaskCompleted()) {
        isDisabled = true
      }
    } else if (taskType == EcrWorkflowTask.IDENTITY_VERIFICATION) {
      const currentTask = workflow?.findTaskByType(
        EcrWorkflowTask.IDENTITY_VERIFICATION
      )
      const prevTask = workflow?.findTaskByType(
        EcrWorkflowTask.PROPOSE_RECIPIENTS
      )
      if (!prevTask?.isTaskCompleted() && !getIsCompletedOrNot(currentTask)) {
        isDisabled = true
      }
    } else if (taskType == EcrWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY) {
      const currentTask = workflow?.findTaskByType(
        EcrWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY
      )
      const prevTask = workflow?.findTaskByType(
        EcrWorkflowTask.IDENTITY_VERIFICATION
      )
      if (!getIsCompletedOrNot(prevTask) && !currentTask?.isTaskCompleted()) {
        isDisabled = true
      }
    } else if (taskType == EcrWorkflowTask.ISSUANCE_CEREMONY) {
      const currentTask = workflow?.findTaskByType(
        EcrWorkflowTask.ISSUANCE_CEREMONY
      )
      const prevTask = workflow?.findTaskByType(
        EcrWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY
      )
      if (!prevTask?.isTaskCompleted() && !getIsCompletedOrNot(currentTask)) {
        isDisabled = true
      }
    } else {
    }
    return isDisabled
  }

  const getIsCompletedOrNot = (task: Task) => {
    if (!task) return false
    if (task.actors.length === 0) {
      return false
    }
    if (task?.taskType == EcrWorkflowTask.IDENTITY_VERIFICATION) {
      if (task.actors.every((actor) => actor.status === TaskStatus.REJECTED))
        return false
      return (
        task.actors.every(
          (actor) =>
            actor.status === TaskStatus.COMPLETE ||
            actor.status === TaskStatus.REJECTED
        ) ||
        (authentication.userInfo.id != workflow.getRequesterId() &&
          task.actors.some(
            (t) =>
              t.userId === authentication.userInfo.id &&
              t.status === TaskStatus.COMPLETE
          ))
      )
    } else {
      return task?.isTaskCompleted()
    }
  }

  const getTaskStatus = (task: Task) => {
    if (!task) return TaskStatus.NOT_ASSIGNED
    if (task.actors.length === 0) return TaskStatus.NOT_ASSIGNED
    if (task.taskType === EcrWorkflowTask.IDENTITY_VERIFICATION) {
      if (task.actors.every((t) => t.status === TaskStatus.REJECTED))
        return TaskStatus.TERMINATED
      if (
        task.actors.every(
          (t) =>
            t.status === TaskStatus.REJECTED || t.status === TaskStatus.COMPLETE
        ) ||
        (authentication.userInfo.id != workflow.getRequesterId() &&
          task.actors.some(
            (t) =>
              t.userId === authentication.userInfo.id &&
              t.status === TaskStatus.COMPLETE
          ))
      )
        return TaskStatus.COMPLETE
    }
    return task.calculateTaskStatus()
  }

  const createTaskMenuItem = (
    menuItem: any,
    isSelected: boolean,
    isCompleted: boolean,
    isDisabled: boolean,
    workflowId: string,
    taskStatus: TaskStatus
  ): TaskMenuItem =>
    ({
      taskType: menuItem.taskType,
      label: menuItem.label,
      href: getHref(menuItem, workflow?.getRequesterId()),
      isSelected,
      isCompleted,
      grants: menuItem.grants,
      excludes: menuItem.excludes,
      isDisabled: isDisabled,
      workflowInstanceId: workflowId,
      taskStatus
    } as TaskMenuItem)

  const prepareSideMenuItems = () => {
    const publicItems = EcrTaskPageMapping.filter((item) => item.isPublic)
    const tasks = publicItems.map((menuItem) => {
      const task = workflow?.findTaskByType(EcrWorkflowTask[menuItem.taskType])
      const isSelected = pathname.startsWith(menuItem.href)
      const isCompleted =
        workflow?.getRequesterId() != authentication.userInfo.id &&
        EcrWorkflowTask[menuItem.taskType] === TaskType.IDENTITY_VERIFICATION
          ? getTaskStatus(task) === TaskStatus.COMPLETE
          : task?.isTaskCompleted() ?? false
      const workflowId = workflow?.getId() ?? null
      const isDisabled = getDisabledOrNot(EcrWorkflowTask[menuItem.taskType])
      const taskStatus = getTaskStatus(task) ?? null

      return createTaskMenuItem(
        menuItem,
        isSelected,
        isCompleted,
        isDisabled,
        workflowId,
        taskStatus
      )
    })

    setTaskMenuItems(tasks)
  }

  useEffect(() => {
    prepareSideMenuItems()
  }, [workflow])

  return (
    <div className="bg-white border rounded shadow-sm border-grey200">
      <p className="p-3 text-base font-semibold">
        <FormattedMessage id="heading.tasksOverView" />
      </p>

      <ul>
        {taskMenuItems.map((menu, index) => (
          <SideMenuItem
            menuItem={menu}
            index={index}
            key={index}
          ></SideMenuItem>
        ))}
      </ul>
    </div>
  )
}
