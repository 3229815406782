import Link from 'next/link'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import tailwindConfig from 'tailwind.config'
import CircleIcon from '@/components/icons/CircleIcon'
import { TaskMenuItem } from '@/types/task-menu-item'
import VerifyRoundIcon from '@/components/icons/VerifyRoundIcon'
import TaskStatus from '@/enums/taskStatus'
import CloseIcon from '@/components/icons/CloseIcon'

interface IProps {
  menuItem?: TaskMenuItem
  index: number
}

export const SideMenuItem = ({ index, menuItem, ...props }: IProps) => {
  const {} = props

  const getActiveLinkClassNames = (isSelected: boolean) => {
    if (isSelected)
      return 'bg-pressedMenuItem border-y border-t-primaryAdditional200 border-b-primaryAdditional200'
    else return ''
  }

  const formatTaskUrl = () => {
    if (menuItem.workflowInstanceId)
      return `${menuItem.href}?workflow_id=${menuItem.workflowInstanceId}`
    else return `${menuItem.href}`
  }

  return (
    <>
      <li
        key={index}
        className={`border-t border-t-grey200 text-base font-medium ${getActiveLinkClassNames(
          menuItem.isSelected
        )}`}
      >
        <Link
          href={formatTaskUrl()}
          className={`flex gap-2 items-center p-3 ${
            !!getActiveLinkClassNames(menuItem.isSelected) && 'text-royalBlue'
          } ${
            menuItem.taskStatus === TaskStatus.TERMINATED && '!text-red-900'
          } ${index === 0 && 'pointer-events-none'}
            ${menuItem.isDisabled == true && ' pointer-events-none opacity-40'}
            `}
        >
          {menuItem.isCompleted === true ? (
            <VerifyRoundIcon mode="success" size={24} />
          ) : menuItem.taskStatus === TaskStatus.TERMINATED ? (
            <span className="w-6 h-6">
              <CloseIcon className="w-6 h-6" />
            </span>
          ) : (
            <CircleIcon
              size={24}
              color={
                !!getActiveLinkClassNames(menuItem.isSelected)
                  ? tailwindConfig.theme.extend.colors.royalBlue
                  : tailwindConfig.theme.extend.colors.neutralsAdditional500
              }
            />
          )}
          <span
            className={`text-base break-all ${
              menuItem.isCompleted === true ? 'line-through' : ''
            }`}
          >
            <FormattedMessage id={menuItem.label} />
          </span>
        </Link>
      </li>
    </>
  )
}
